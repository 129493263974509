import React, { useState } from 'react';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { productsApiKey, productsUrl } from '../services/constants';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const NewProductPage: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null); // State for name validation error
  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    tags: '',
    unitPrice: 0,
    unitType: '',
    unitVolume: 0,
    stock: 0,
    minimumStockThreshold: 0,
    compositionFormulaPercentage: 0,
    productFormula: '00000000-0000-0000-0000-000000000000',
    isAvailable: false,
    categories: [] as string[],
  });

  const handleChange = (field: string, value: any) => {
    setNewProduct((prev) => ({ ...prev, [field]: value }));
    if (field === 'name' && value.trim() === '') {
      setNameError('Name is required');
    } else if (field === 'name') {
      setNameError(null);
    }
  };

  const handleCreateProduct = async () => {
    if (newProduct.name.trim() === '') {
      setNameError('Name is required');
      return; // Prevent submission if name is empty
    }

    try {
      const createdProduct = await axios.post(`${productsUrl}/api/v1/products`, newProduct, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
          "x-api-key": productsApiKey,
        },
      });
      navigate(`/products/${createdProduct.data.id}`);
    } catch (error) {
      console.error('Error creating product:', error);
      setError('Error creating product');
    }
  };

  return (
    <Box sx={{ padding: 3, maxWidth: 600, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Add New Product
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <TextField
        label="Name"
        fullWidth
        margin="normal"
        value={newProduct.name}
        onChange={(e) => handleChange('name', e.target.value)}
        error={!!nameError} // Display error styling if nameError is set
        helperText={nameError} // Display error message below the field
      />
      <TextField
        label="Description"
        fullWidth
        margin="normal"
        multiline
        rows={3}
        value={newProduct.description}
        onChange={(e) => handleChange('description', e.target.value)}
      />
      <TextField
        label="Tags"
        fullWidth
        margin="normal"
        value={newProduct.tags}
        onChange={(e) => handleChange('tags', e.target.value)}
      />
      <TextField
        label="Unit Price"
        type="number"
        fullWidth
        margin="normal"
        value={newProduct.unitPrice}
        onChange={(e) => handleChange('unitPrice', parseFloat(e.target.value))}
      />
      <TextField
        label="Stock"
        type="number"
        fullWidth
        margin="normal"
        value={newProduct.stock}
        onChange={(e) => handleChange('stock', parseFloat(e.target.value))}
      />
      <TextField
        label="Minimum Stock Threshold"
        type="number"
        fullWidth
        margin="normal"
        value={newProduct.minimumStockThreshold}
        onChange={(e) => handleChange('minimumStockThreshold', parseFloat(e.target.value))}
      />
      {/* Add other fields as necessary */}
      
      <Button variant="contained" color="primary" onClick={handleCreateProduct} sx={{ mt: 2, mr: 2 }}>
        Create Product
      </Button>
      <Button variant="outlined" color="secondary" onClick={() => navigate('/products')} sx={{ mt: 2 }}>
        Back to Product List
      </Button>
    </Box>
  );
};

export default NewProductPage;