import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Button, Box, useTheme } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import GroupIcon from '@mui/icons-material/Group';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const drawerWidth = 250;

const Sidebar: React.FC = () => {
  const theme = useTheme();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.background.sidebar, // Sidebar color from theme
          color: theme.palette.common.white, // White text for contrast
        },
      }}
    >
      <Box sx={{ width: drawerWidth, paddingTop: 2, paddingLeft: 2 }}>
        <Typography variant="h5" gutterBottom sx={{ color: theme.palette.common.white }}>
          Admin Portal
        </Typography>

        <List>
          <ListItem component={Link} to="/dashboard" sx={{ color: theme.palette.common.white }}>
            <ListItemIcon><DashboardIcon sx={{ color: theme.palette.common.white }} /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>

          <ListItem component={Link} to="/products" sx={{ color: theme.palette.common.white }}>
            <ListItemIcon><ShoppingCartIcon sx={{ color: theme.palette.common.white }} /></ListItemIcon>
            <ListItemText primary="Product Management" />
          </ListItem>

          <ListItem component={Link} to="/orders" sx={{ color: theme.palette.common.white }}>
            <ListItemIcon><ShoppingCartIcon sx={{ color: theme.palette.common.white }} /></ListItemIcon>
            <ListItemText primary="Order Management" />
          </ListItem>

          <ListItem component={Link} to="/stock-management" sx={{ color: theme.palette.common.white }}>
            <ListItemIcon><InventoryIcon sx={{ color: theme.palette.common.white }} /></ListItemIcon>
            <ListItemText primary="Inventory Management" />
          </ListItem>

          <ListItem component={Link} to="/customers" sx={{ color: theme.palette.common.white }}>
            <ListItemIcon><GroupIcon sx={{ color: theme.palette.common.white }} /></ListItemIcon>
            <ListItemText primary="Customer Management" />
          </ListItem>

          <ListItem component={Link} to="/sales" sx={{ color: theme.palette.common.white }}>
            <ListItemIcon><AssessmentIcon sx={{ color: theme.palette.common.white }} /></ListItemIcon>
            <ListItemText primary="Sales Reports" />
          </ListItem>
        </List>

        <Box sx={{ position: 'absolute', bottom: 20, left: 10 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleLogout}
            startIcon={<LogoutIcon />}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;