// src/pages/StockManagementPage.tsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Select,
  MenuItem,
} from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { productsApiKey, productsUrl } from '../services/constants';
import { StockUpdate } from '../types';

const changeTypeOptions = [
    { value: -1, label: '' },
    { value: 0, label: 'Purchase' },
    { value: 3, label: 'Manual Adjustment Increase' },
    { value: 4, label: 'Manual Adjustment Decrease' },
  ];

const StockManagementPage: React.FC = () => {
  const { user } = useAuth();
  const [products, setProducts] = useState<StockUpdate[]>([]);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);


  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${productsUrl}/api/v1/all-products`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
          "x-api-key": productsApiKey,
        },
      });

      const initialProducts = response.data.products.map((product: any) => ({
        id: product.id,
        name: product.name,
        stock: product.stock,
        changeAmount: 0,
        changeType: -1,
        changeReason: '',
      }));
      setProducts(initialProducts);
    } catch (error) {
      console.error('Error fetching products:', error);
      setAlertMessage('Error fetching products.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user)
    {
        fetchProducts();
    }
  }, [user]);

  const handleChange = (
    id: string,
    field: keyof StockUpdate,
    value: string | number
  ) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id ? { ...product, [field]: value } : product
      )
    );
  };

const saveChanges = async () => {
  const invalidProducts = products.filter(
    (product) =>
      product.changeAmount > 0 &&
      (product.changeReason.trim() === '' || product.changeType === -1)
  );

  if (invalidProducts.length > 0) {
    setAlertMessage('Please provide a Change Type and Reason for all adjustments.');
    return;
  }

  const payload = {
    stockUpdates: products
      .filter((product) => product.changeAmount > 0)
      .map((product) => ({
        productId: product.id,
        changeType: product.changeType,
        quantityChange: product.changeAmount,
        changeReason: product.changeReason,
      })),
  };

  try {
    await axios.post(
      `${productsUrl}/api/v1/products/update-stock`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${user?.token}`,
          "x-api-key": productsApiKey,
        },
      }
    );
    setAlertMessage('Stock levels updated successfully!');
    setProducts((prevProducts) =>
      prevProducts.map((product) => ({
        ...product,
        changeAmount: 0,
        changeReason: '',
        changeType: -1,
      }))
    );
    fetchProducts();
  } catch (error) {
    console.error('Error updating stock:', error);
    setAlertMessage('Failed to update stock levels.');
  }
};

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Manage Stock Adjustments
      </Typography>
      {alertMessage && (
        <Typography variant="body1" color="error" gutterBottom>
          {alertMessage}
        </Typography>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Current Stock</TableCell>
              <TableCell>Change Type</TableCell>
              <TableCell>Change Amount</TableCell>
              <TableCell>Change Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => {
                const isInvalid = product.changeAmount > 0 && product.changeReason.trim() === '';
                return (
                <TableRow
                    key={product.id}
                    sx={{
                    backgroundColor: isInvalid ? 'rgba(255, 0, 0, 0.1)' : 'inherit',
                    }}
                >
                    <TableCell>
                    <Link
                        to={`/products/${product.id}`}
                        style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                    >
                        {product.name}
                    </Link>
                    </TableCell>
                    <TableCell>{product.stock}</TableCell>
                    <TableCell>
                    <Select
                        value={product.changeType}
                        onChange={(e) =>
                        handleChange(product.id, 'changeType', parseInt(e.target.value as string, 10))
                        }
                        fullWidth
                    >
                        {changeTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                        ))}
                    </Select>
                    </TableCell>
                    <TableCell>
                    <TextField
                        type="number"
                        value={product.changeAmount}
                        onChange={(e) =>
                        handleChange(product.id, 'changeAmount', parseInt(e.target.value, 10))
                        }
                        fullWidth
                    />
                    </TableCell>
                    <TableCell>
                    <TextField
                        value={product.changeReason}
                        onChange={(e) =>
                        handleChange(product.id, 'changeReason', e.target.value)
                        }
                        error={isInvalid}
                        helperText={isInvalid ? 'Reason is required for adjustments' : ''}
                        fullWidth
                    />
                    </TableCell>
                </TableRow>
                );
            })}
            </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={saveChanges}>
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default StockManagementPage;