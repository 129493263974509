// src/pages/ProductListPage.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch, TextField, FormControlLabel, Alert, TableSortLabel } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { Metadata, Product } from '../types';
import { productsApiKey, productsUrl } from '../services/constants';
import { Link, useNavigate } from 'react-router-dom';

type SortColumn = 'name' | 'unitPrice' | 'stock' | 'updatedAt';

const ProductListPage: React.FC = () => {
  const { user } = useAuth();
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [showAvailableOnly, setShowAvailableOnly] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<'error' | 'info' | undefined>(undefined);
  const [sortColumn, setSortColumn] = useState<SortColumn>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const perfumePlaceholderImage = require("../assets/images/perfume_product_placeholder.png");
  const navigate = useNavigate();

  const fetchProducts = async (url: string = `${productsUrl}/api/v1/all-products`) => {
    try {
      setLoading(true);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
          "x-api-key": productsApiKey
        },
      });
      const data = response.data;
      setProducts(data.products);
      setMetadata(data.metadata);
    } catch (error) {
      console.error('Error fetching products:', error);
      showAlert('Error fetching products', 'error');
    } finally {
      setLoading(false);
    }
  };

  const showAlert = (message: string, type: 'error' | 'info') => {
    setAlertMessage(message);
    setAlertType(type);
    setTimeout(() => {
      setAlertMessage(null);
      setAlertType(undefined);
    }, 3000);
  };

  useEffect(() => {
    fetchProducts();
  }, [user?.token]);

  useEffect(() => {
    const filtered = products.filter((product) => {
      const matchesFilterText = product.name.toLowerCase().includes(filterText.toLowerCase());
      const matchesAvailability = showAvailableOnly ? product.isAvailable : true;
      return matchesFilterText && matchesAvailability;
    });

    // Sort the filtered products based on the selected sort column and direction
    const sorted = [...filtered].sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      let comparison = 0;
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        comparison = aValue.localeCompare(bValue);
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        comparison = aValue - bValue;
      } else if (new Date(aValue) instanceof Date && new Date(bValue) instanceof Date) {
        comparison = new Date(aValue).getTime() - new Date(bValue).getTime();
      }

      return sortDirection === 'asc' ? comparison : -comparison;
    });

    setFilteredProducts(sorted);
  }, [products, filterText, showAvailableOnly, sortColumn, sortDirection]);

  const handleSort = (column: SortColumn) => {
    const isAsc = sortColumn === column && sortDirection === 'asc';
    setSortColumn(column);
    setSortDirection(isAsc ? 'desc' : 'asc');
  };

  const handleAvailabilityToggle = async (productId: string, newValue: boolean) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, isAvailable: newValue } : product
      )
    );

    try {
      await axios.patch(
        `${productsUrl}/api/v1/products/${productId}/availability`,
        { isAvailable: newValue },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
            "x-api-key": productsApiKey,
          },
        }
      );
      showAlert('Product availability updated successfully', 'info');
    } catch (error) {
      console.error('Error updating availability:', error);
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId ? { ...product, isAvailable: !newValue } : product
        )
      );
      showAlert('Error updating product availability', 'error');
    }
  };

  const handleDeleteProduct = async (productId: string) => {
    try {
      await axios.delete(`${productsUrl}/api/v1/products/${productId}`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
          "x-api-key": productsApiKey,
        },
      });
      setProducts((prevProducts) => prevProducts.filter((product) => product.id !== productId));
      showAlert('Product deleted successfully', 'info');
    } catch (error) {
      console.error("Error deleting product:", error);
      showAlert('Failed to delete product', 'error');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ height: '48px', mb: 2 }}>
        {alertMessage && (
          <Alert severity={alertType} variant="filled">
            {alertMessage}
          </Alert>
        )}
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Products</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate('/products/new')}>
          Add New Product
        </Button>
      </Box>

      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <TextField
          label="Filter by name"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <FormControlLabel
          control={
            <Switch
              checked={showAvailableOnly}
              onChange={(e) => setShowAvailableOnly(e.target.checked)}
              color="primary"
            />
          }
          label="Available only"
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell sortDirection={sortColumn === 'name' ? sortDirection : false}>
                <TableSortLabel
                  active={sortColumn === 'name'}
                  direction={sortColumn === 'name' ? sortDirection : 'asc'}
                  onClick={() => handleSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sortColumn === 'unitPrice' ? sortDirection : false}>
                <TableSortLabel
                  active={sortColumn === 'unitPrice'}
                  direction={sortColumn === 'unitPrice' ? sortDirection : 'asc'}
                  onClick={() => handleSort('unitPrice')}
                >
                  Price
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sortColumn === 'stock' ? sortDirection : false}>
                <TableSortLabel
                  active={sortColumn === 'stock'}
                  direction={sortColumn === 'stock' ? sortDirection : 'asc'}
                  onClick={() => handleSort('stock')}
                >
                  Stock
                </TableSortLabel>
              </TableCell>
              <TableCell>Volume</TableCell>
              <TableCell>Availability</TableCell>
              <TableCell sortDirection={sortColumn === 'updatedAt' ? sortDirection : false}>
                <TableSortLabel
                  active={sortColumn === 'updatedAt'}
                  direction={sortColumn === 'updatedAt' ? sortDirection : 'asc'}
                  onClick={() => handleSort('updatedAt')}
                >
                  Last Updated
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProducts.map((product) => (
              <TableRow key={product.id}>
                <TableCell>
                  <img
                    src={product.productImages[0]?.fileName || perfumePlaceholderImage}
                    alt={product.name}
                    width="50"
                    height="50"
                  />
                </TableCell>
                <TableCell>
                  <Link to={`/products/${product.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {product.name}
                  </Link>
                </TableCell>
                <TableCell>£{product.unitPrice.toFixed(2)}</TableCell>
                <TableCell>{product.stock}</TableCell>
                <TableCell>
                  {product.unitVolume} {product.unitType}
                </TableCell>
                <TableCell>
                  <Switch
                    checked={product.isAvailable}
                    onChange={(e) => handleAvailabilityToggle(product.id, e.target.checked)}
                    color="primary"
                  />
                </TableCell>
                <TableCell>{new Date(product.updatedAt).toLocaleDateString()}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => handleDeleteProduct(product.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="space-between" mt={4}>
        {metadata?.previousPage && (
          <Button variant="contained" onClick={() => fetchProducts(metadata.previousPage!)}>
            Previous
          </Button>
        )}
        {metadata?.nextPage && (
          <Button variant="contained" onClick={() => fetchProducts(metadata.nextPage!)}>
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ProductListPage;