export const baseUrl = process.env.REACT_APP_BASE_URL;
export const authUrl = process.env.REACT_APP_AUTH_URL;
export const productsUrl = process.env.REACT_APP_PRODUCTS_URL;
export const fulfilmentUrl = process.env.REACT_APP_FULFILMENT_URL;
export const mediaUrl = process.env.REACT_APP_MEDIA_URL;
export const baseApiKey = process.env.REACT_APP_BASE_API_KEY;
export const productsApiKey = process.env.REACT_APP_PRODUCTS_API_KEY;
export const authApiKey = process.env.REACT_APP_AUTH_API_KEY;
export const mediaApiKey = process.env.REACT_APP_MEDIA_API_KEY;
export const fulfilmentApiKey = process.env.REACT_APP_FULFILMENT_API_KEY;
export const baseImageUrl = `${baseUrl}/images`;
export const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

