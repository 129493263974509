import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { productsApiKey, productsUrl } from '../services/constants';

const changeTypeOptions = [
  { value: '', label: '' }, // Blank option for validation
  { value: 0, label: 'Purchase' },
  { value: 3, label: 'Manual Adjustment Increase' },
  { value: 4, label: 'Manual Adjustment Decrease' },
];

interface StockManagementWidgetProps {
  productId: string;
  userToken: string;
}

const StockManagementWidget: React.FC<StockManagementWidgetProps> = ({ productId, userToken }) => {
  const [changeType, setChangeType] = useState<string | number>('');
  const [changeAmount, setChangeAmount] = useState<number>(0);
  const [changeReason, setChangeReason] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [info, setInfo] = useState<string | null>(null);

  const handleStockUpdate = async () => {
    setError(null);
    setInfo(null);

    // Validation
    if (changeAmount <= 0) {
      setError('Change amount must be greater than zero.');
      return;
    }
    if (changeType === '') {
      setError('Please select a change type.');
      return;
    }
    if (changeReason.trim() === '') {
      setError('Please provide a reason for the stock adjustment.');
      return;
    }

    const payload = {
      stockUpdates: [
        {
          productId,
          changeType,
          quantityChange: changeAmount,
          changeReason,
        },
      ],
    };

    try {
      await axios.post(`${productsUrl}/api/v1/products/update-stock`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "x-api-key": productsApiKey,
        },
      });
      setInfo('Stock updated successfully!');
      setChangeType('');
      setChangeAmount(0);
      setChangeReason('');
    } catch (error) {
      console.error('Error updating stock:', error);
      setError('Failed to update stock.');
    }
  };

  console.log(productId);

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6">Manage Stock Levels</Typography>
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
      {info && <Alert severity="info" sx={{ mt: 2 }}>{info}</Alert>}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel>Change Type</InputLabel>
          <Select
            value={changeType}
            onChange={(e) => setChangeType(e.target.value)}
            displayEmpty
          >
            {changeTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Change Amount"
          type="number"
          fullWidth
          value={changeAmount}
          onChange={(e) => setChangeAmount(parseInt(e.target.value, 10))}
        />
        <TextField
          label="Change Reason"
          fullWidth
          value={changeReason}
          onChange={(e) => setChangeReason(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleStockUpdate}
        >
          Update Stock
        </Button>
      </Box>
    </Box>
  );
};

export default StockManagementWidget;