import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import Sidebar from './components/Sidebar';
import ProtectedRoute from './components/ProtectedRoute';
import { Box } from '@mui/material';
import ProductListPage from './pages/ProductListPage';
import ProductDetailsPage from './pages/ProductDetailsPage';
import NewProductPage from './pages/NewProductPage';
import StockManagementPage from './pages/StockManagementPage';

// Placeholder components for each page
const Orders = () => <div>Order Management Page</div>;
const Inventory = () => <div>Inventory Management Page</div>;
const Customers = () => <div>Customer Management Page</div>;
const Sales = () => <div>Sales Reports Page</div>;

const drawerWidth = 250; // Width of the sidebar

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Login page */}
        <Route path="/" element={<LoginPage />} />

        {/* Main layout with Sidebar */}
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <Box sx={{ display: 'flex' }}>
                {/* Sidebar */}
                <Sidebar />

                {/* Main content area */}
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                  }}
                >
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/products" element={<ProtectedRoute><ProductListPage /></ProtectedRoute>} />
                    <Route path="/products/new" element={<ProtectedRoute><NewProductPage /></ProtectedRoute>} />
                    <Route path="/products/:id" element={<ProtectedRoute><ProductDetailsPage /></ProtectedRoute>} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/stock-management" element={<ProtectedRoute><StockManagementPage /></ProtectedRoute>} />
                    <Route path="/customers" element={<Customers />} />
                    <Route path="/sales" element={<Sales />} />
                  </Routes>
                </Box>
              </Box>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;