import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, TextField, Typography, Alert } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { authApiKey, authUrl } from '../services/constants';

const perfumePlaceholderImage = require('../assets/images/material_placeholder.png');

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${authUrl}/api/v1/account/login`, {
        username,
        password,
      },{
      headers: {
        "x-api-key": authApiKey,
      }
    });

      const data = response.data;

      if (data.status === 'Success') {
        // Store the user data, including the token, in localStorage
        localStorage.setItem('user', JSON.stringify(data.user));

        // Call login to update authentication context and redirect to the dashboard
        login(data.user);
        navigate('/dashboard');
      } else {
        setError(data.message || 'Login failed');
      }
    } catch (error: any) {
      setError(error.response?.data?.message || 'Network error. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ paddingTop: 6 }}>
      <Box display="flex" justifyContent="center" mb={4}>
        <Box
          component="img"
          src={perfumePlaceholderImage}
          alt="Perfume Product"
          sx={{ width: '50%', height: 'auto' }}
        />
      </Box>
      <Box mt={4} sx={{ paddingTop: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Myperfumehub.com Admin Login
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          label="Username"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={handleLogin}>
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default LoginPage;